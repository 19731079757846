import React, { useCallback, useEffect, useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import useScrollListener from '../../hooks/use-scroll-listener';
import { useSelector } from '../runtime-context';

export type LoadMoreProps = React.PropsWithChildren<{
  hasMore?: boolean;
  /** Page from which component starts tracking the current page */
  pageStart?: number;
  threshold?: number;
  loadMore: (page: number) => Promise<any>;
  loader?: React.ReactNode;
  isLoading?: boolean;
}>;

const LoadMore: React.FC<LoadMoreProps> = ({
  hasMore = false,
  pageStart = 2,
  threshold = 250,
  children,
  loader,
  isLoading: isLoadingProp,
  loadMore,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = useRefPage(pageStart);
  const { isSSR, isEditor } = useEnvironment();
  const containerRef = useRef<HTMLElement | null>(null);
  const componentId = useSelector((_, host) => host.id);

  useEffect(() => {
    containerRef.current = document.getElementById(componentId);
  }, [componentId]);

  const handleLoad = useCallback(() => {
    if (isLoadingProp || isLoading) {
      return;
    }

    setIsLoading(true);

    loadMore(ref.current.page).finally(() => {
      setIsLoading(false);
    });

    ref.current.page++;
  }, [isLoadingProp, isLoading, loadMore, ref]);

  const shouldLoadMore = useCallback(() => {
    if (!hasMore || isLoadingProp || isLoading || isSSR) {
      return false;
    }

    if (isEditor && ref.current.page > 2) {
      return false;
    }

    const component = containerRef.current;

    if (component == null) {
      return false;
    }

    const { height, top } = component.getBoundingClientRect();

    return window.innerHeight + threshold > height + top;
  }, [hasMore, isEditor, isLoading, isLoadingProp, isSSR, ref, threshold]);

  const handleScroll = useCallback(() => {
    if (shouldLoadMore()) {
      handleLoad();
    }
  }, [handleLoad, shouldLoadMore]);

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollListener(handleScroll);

  return (
    <div>
      {children}
      {hasMore && (isLoadingProp || isLoading) && loader}
    </div>
  );
};

const useRefPage = (pageStart: number) => {
  const ref = React.useRef({ page: pageStart, pageStart });

  // Reset page when pageStart prop changes
  useEffect(() => {
    const pageStartChanged = pageStart !== ref.current.pageStart;

    if (pageStartChanged) {
      ref.current = { page: pageStart, pageStart };
    }
  }, [pageStart]);

  return ref;
};

export default LoadMore;
